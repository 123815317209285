<template>
  <div>
    <v-card>
        <v-card-text>
            <v-data-table :loading="progress" loading-text="Cargando....." dark :search="likesearch" :headers="headers" :items="desserts" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                <template v-slot:item.tStock="{ item }">
                    <v-chip small :color="CriticalSearch(item.tSku,item.tBodega)" dark>
                        {{ item.tStock }}
                    </v-chip>                  
                    <!-- <v-chip small :color="getColor(item.numStock,item.numCritico)" dark>
                        {{ item.tStock }}
                    </v-chip> -->
                </template>
                <template v-slot:item.ruta="{ item }">
                  <a target="_blank" :href="item.ruta"><v-icon class="white--text" small >photo_library</v-icon></a>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination v-model="page" :length="pageCount" total-visible="10"></v-pagination>
                </v-col>
                <v-col cols="10" md="3">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                </v-col>
                <v-col cols="2" md="1" class="text-right">
                    <a v-if="descargaCSV===null" href="javascript:void(0)" style="text-decoration: none">
                        <v-btn class="mt-1 ml-2" small color="white" rounded><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></v-btn>
                    </a>
                    <a v-else-if="descargaCSV===false" href="javascript:void(0)" @click="exportCSV()" style="text-decoration: none">
                        <v-btn class="mt-1 ml-2" small color="white" rounded><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></v-btn>
                    </a>
                    <a v-else :href="descarga" style="text-decoration: none">
                        <v-btn class="mt-1 ml-2" small color="white" rounded><v-icon class="blue-grey--text">cloud_download</v-icon></v-btn>
                    </a>
                </v-col>
          </v-row>
          </v-container>
        </v-card-actions>
    </v-card>
  </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import axios from 'axios';
  export default {
    name:'Stock',
    props: ['usu','contrato'],
    data () {
      return {
        //contrato:'123',
        likesearch:'',
        page: 1,
        pageCount: 0,
        itemsPerPage: 8,
        progress:false,        
        headers: [
          { text: 'Sku', align: 'start', sortable: true, value: 'tSku' },
          { text: 'Barra', align: 'start', sortable: true, value: 'tBarra' },
          { text: 'Producto', align: 'start', sortable: true, value: 'tNombre' },
          { text: 'Bodega', align: 'start', sortable: true, value: 'tBodega' },
          { text: 'Stock', align: 'center', sortable: true, value: 'tStock' },
          { text: '($) Detalle', align: 'center', sortable: true, value: 'tDetalle' },
          { text: '($) x Mayor', align: 'center', sortable: true, value: 'tMayor' },
          { text: '($) Oferta', align: 'center', sortable: true, value: 'tOferta' },
          { text: '(%) Stock', align: 'center', sortable: true, value: 'tPorcentaje' },
          { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
        ],
        desserts: [], 
        SkuCritical:[],
        descarga:'javascript:void(0)',
        descargaCSV:null,
      }
    },
    mounted(){      
      this.generaTabla()
      this.allCritical()      
      this.descargaCSV=false;
    },
    methods: {
      async exportCSV(){
        try {
            this.loading=true;
            this.descarga='javascript:void(0)';
            var date = new Date();
            const formatDate = (current_datetime)=>{
                let formatted_date = current_datetime.getFullYear() + "" + (current_datetime.getMonth() + 1) + "" + current_datetime.getDate() + "" + current_datetime.getHours() + "" + current_datetime.getMinutes() + "" + current_datetime.getSeconds();
                return formatted_date;
            };
            const nameFile = 'inventario_'+formatDate(date);
            let csv;
            csv=[];
            let params;
            let response;
            //titulo
            csv.push({
                'tSku':'Sku',
                'tBarra':'Barra',
                'tNombre':'Producto',
                'tBodega':'Bodega',
                'tStock':'Stock',
                'tDetalle':'($) Detalle',
                'tMayor':'($) x Mayor',
                'tOferta':'($) Oferta',
                'tPorcentaje':'(%) Stock'
            });
            //datos
            this.desserts.forEach(element => {
                csv.push({
                    'tSku':element.tSku,
                    'tBarra':element.tBarra,
                    'tNombre':element.tNombre,
                    'tBodega':element.tBodega,
                    'tStock':element.tStock,
                    'tDetalle':element.tDetalle,
                    'tMayor':element.tMayor,
                    'tOferta':element.tOferta,
                    'tPorcentaje':element.tPorcentaje
                })
            });
            params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
            response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/csv/nuevo',params);
            if (response.data=='si'){
                this.descarga='https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                this.descargaCSV=true;
            };
            this.loading=false;    
        } catch (error) {
          console.log(error);
        }
      },

      async generaTabla(){
        try {
          this.progress=true;
          const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock/datatable/'+this.contrato);
          const datos=response.data;
          //console.log(datos);
          this.desserts=[];
          if (datos[0].existe=='si'){
            datos.forEach(element => {
              let html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/producto/integracion/web/'+this.contrato+'/'+element.Sku;
              this.desserts.push({
                tSku: element.Sku,
                tNombre: element.Nombre,
                tBarra: element.Barra,
                tBodega: element.Bodega,
                tStock: new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                tDetalle: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioLocal)),
                tMayor: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioMayor)),
                tOferta: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioOferta)),
                tPorcentaje: new Intl.NumberFormat("de-DE").format(parseFloat(element.Porcentaje))+'%',
                numCritico: element.Critico,
                numStock: element.Total,
                ruta:html
              })
            });
          }
          this.progress=false;          
        } catch (error) {
          console.log(error)
        }
      },
      async allCritical(){
        try {
          this.progress=true;
          this.SkuCritical=[];
          const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/report/calculostock/'+this.contrato)
          const datos=response.data;
          //console.log(datos);
          if (datos[0].existe=='si'){
              datos.forEach(element => {
                this.SkuCritical.push({
                  cSku: element.Sku,
                  cBodega: element.Bodega,
                  cStock: element.Stock,
                  cP3M: element.P3M,
                  cCritico:element.Critico
                })
              });
          }
          this.progress=false;
          //console.log(this.SkuCritical);         
        } catch (error) {
          console.log(error)
        }
      },
      CriticalSearch(tSku,tBodega){
        //console.log(tSku,tBodega);
        const resultado = this.SkuCritical.find( arreglo => arreglo.cSku === tSku && arreglo.cBodega === tBodega);
        if (resultado){
          if (resultado.cCritico!=0){ return 'red' }
          else { 
            if ((resultado.cStock/2) < resultado.cP3M) { return 'orange' }
            else { return 'green' }
          }
        } 
      },
    },
  }
</script>