<template>
    <div>
        <h3>Historico de Ventas</h3>
        <v-divider></v-divider>
        <GChart type="ColumnChart" :data="chartdata"/>
        <!-- <GChart type="LineChart" :data="chartData" :options="chartOptions" />           -->
    </div>
</template>

<script>
import axios from 'axios';
import { GChart } from 'vue-google-charts';
export default {
  name: 'ChartVentas',
  components: { GChart },
  props:['usu','contrato'],
  data(){
    return {
      //contrato:'123',
      Mes:'',
      Monto:'',
      data : new google.visualization.DataTable(),
      chartdata: new google.visualization.DataTable()
    //   chartOptions: {
    //     chart: {
    //       title: 'Historico de Ventas',
    //       subtitle: '2021',
    //     }
    //   }
  }},
  async mounted(){
    this.data.addColumn('string', 'Mes');
    this.data.addColumn('number', '$');
    const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/cartera/crecimiento/mensual/'+this.contrato);
    const datos=response.data;
    //console.log(datos);
    if (datos[0].existe=='si'){
      datos.forEach(element => {
        switch (element.Mes) {
          case '1':
            this.Mes='Ene';
            this.Monto=element.Ventas;
            break;
          case '2':
            this.Mes='Feb';
            this.Monto=element.Ventas;
            break;
          case '3':
            this.Mes='Mar';
            this.Monto=element.Ventas;
            break;
          case '4':
            this.Mes='Abr';
            this.Monto=element.Ventas;
            break;
          case '5':
            this.Mes='May';
            this.Monto=element.Ventas;
            break;
          case '6':
            this.Mes='Jun';
            this.Monto=element.Ventas;
            break;
          case '7':
            this.Mes='Jul';
            this.Monto=element.Ventas;
            break;
          case '8':
            this.Mes='Ago';
            this.Monto=element.Ventas;
            break;
          case '9':
            this.Mes='Sep';
            this.Monto=element.Ventas;
            break;
          case '10':
            this.Mes='Oct';
            this.Monto=element.Ventas;
            break;
          case '11':
            this.Mes='Nov';
            this.Monto=element.Ventas;
            break;
          case '12':
            this.Mes='Dic';
            this.Monto=element.Ventas;
            break;
        }
          this.data.addRows([
              [this.Mes, parseFloat(this.Monto)],
          ]);
          //console.log(this.Mes, parseFloat(this.Monto))
      });
      this.chartdata=this.data
    }     
  }
}
</script>