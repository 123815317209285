<template>
    <div>
        <v-progress-linear v-if="rueda==true" rounded height="2" indeterminate color="primary"></v-progress-linear>
        <v-card color="mt-1 green darken-3" dark>
            <v-card-title class="text-caption">
              <span class="text-body-2">Ganancias ({{Anio}})</span>
              <v-spacer></v-spacer>
              <v-chip color='green darken-1'>${{new Intl.NumberFormat("de-DE").format(utilidad)}}</v-chip>
            </v-card-title>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'UtilidadVentas',
  props:['usu','contrato'],
    data(){
    return {
      //contrato:'123',
      Anio:'',
      utilidad:0,
      varUtilidad:0,
      rueda:true,
      // new Utilidades
      codigos:[],
      entradas:[],
      salidas:[]
    }
  },
  async mounted(){
    this.Utilidades();
  },
  methods:{
    async Utilidades(){
      this.rueda=true;
      const fecha=new Date();
      const tFecha = fecha.toISOString();
      const hoy=tFecha.substring(0,10);
      const anio=tFecha.substring(0,4);

      this.Anio=anio;
      await this.Util(anio);
      this.rueda=false;
    },
    async Util(anio){
      try {
        // obtenemos todos los sku
        const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/sku/all/'+ this.contrato);
        const datos=response.data;
        if(datos.length!=0){
          this.codigos=datos;
          // obtenemos todos las entradas
          const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/entradas/'+ this.contrato);
          const datos1=response1.data;
          if(datos1.length!=0){
              this.entradas=datos1;
          };
          // obtenemos las salidas
          const response2 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/utilidad/salidas/'+ this.contrato);
          const datos2=response2.data;
          if(datos2.length!=0){
              this.salidas=datos2;
          };
          let HoraInicio = new Date();
          console.log('INICIO');
          console.log(HoraInicio);
          //comenzamos el recorrido por sku
          let arraySalida=[];
          this.codigos.forEach(element => {
            const nSku=element.Sku;
            //rescatamos las entradas
            const arrayIN = this.entradas.filter(res => res.Sku==nSku);
            if(arrayIN){
              // preparamos la entrada
              let ini=0;
              let fin=0;
              let valor=0;
              let arrayEntrada=[];
              for (let index = 0; index < arrayIN.length; index++) {
                  const res = arrayIN[index];
                  ini=fin+1;
                  fin=fin+parseFloat(res.Cantidad);
                  valor=res.Precio;
                  arrayEntrada.push({
                      'sku':res.Sku,
                      'inicio':parseFloat(ini),
                      'final':parseFloat(fin),
                      'precio':parseFloat(valor)
                  });                
              };
              // recorremos las Salidas
              const arrayOUT = this.salidas.filter(res => res.Sku==nSku);
              if(arrayOUT){
                let fec='';
                let rango=0;
                let precio_venta=0;
                let precio_compra=0;
                let utilidad=0;
                for (let index = 0; index < arrayOUT.length; index++) {
                  const res1 = arrayOUT[index];
                  fec=res1.Fecha;
                  if(res1.Origen=='Venta' || res1.Origen=='Devolucion'){
                    precio_venta=parseFloat(res1.Precio);
                    for (let index = 0; index < res1.Cantidad; index++) {  
                      if(res1.Origen=='Venta'){
                          rango=parseInt(rango)+1;
                          //buscar precio compra segun rango
                          const found = arrayEntrada.find(res => rango>=res.inicio && rango<=res.final);
                          if(found){ precio_compra=parseFloat(found.precio) };
                          utilidad=parseFloat(precio_venta)-parseFloat(precio_compra);
                      };
                      if(res1.Origen=='Devolucion'){
                          rango=parseInt(rango)-1;
                          //buscar precio compra segun rango
                          const found = arrayEntrada.find(res => rango>=res.inicio && rango<=res.final);
                          if(found){ precio_compra=parseFloat(found.precio) };
                          utilidad=parseFloat(precio_compra)-parseFloat(precio_venta);
                          // cambiar signo por devolucion
                          precio_venta=parseFloat(precio_venta)*-1
                      }             
                      //array utilidad
                      arraySalida.push({
                          'Fecha':fec,
                          'Sku': res1.Sku,
                          'PrecioCompra':precio_compra,
                          'PrecioVenta':precio_venta,
                          'Utilidad':utilidad,
                          'Origen':res1.Origen
                      })
                    }
                  };
                  if(res1.Origen=='Egreso'){
                    rango=parseInt(rango)-parseFloat(res1.Cantidad);
                  }            
                }
              }
            }
          });
          // filtrar la salida segun lo necesitado
          let filtro = arraySalida.filter(res => parseInt(new Date(res.Fecha+'T00:00:00').getFullYear())==parseInt(anio));
          filtro.forEach(result => {
            this.varUtilidad=parseFloat(this.varUtilidad)+parseFloat(result.Utilidad);
          });
          this.utilidad=parseFloat(this.varUtilidad);
          console.log('FIN');
          let now = new Date();
          console.log(now);
        };                
      } catch (error) {
          console.log(error)
      }
    },

  }
}
</script>