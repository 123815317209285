<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">COMPRA</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="9" md="2">
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="orden" label="N° Compra" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="10" md="4">
                                <v-combobox  class='text-caption' @change="numeroProveedor(proveedor)" :hint="rutProveedor" persistent-hint v-model="proveedor" :items="itemProveedor" label="Proveedor" :disabled="disabledOrden" :filled="filledOrden" dense outlined></v-combobox>
                            </v-col>
                            <v-col cols="2" md="1">
                                <v-btn @click="verOrden(orden,proveedor)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Orden Existe !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    Nueva Orden !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    Selecciona Datos !!!
                                </v-alert>
                            </v-col >
                        </v-row>
                        <!-- <v-row class="mt-n6">
                            <v-col cols="6" md="2" class="text-center">
                                <h5 class="mt-n3">Datos Contables</h5>
                            </v-col>
                            <v-col cols="6" md="10">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row> -->
                        <v-row class="mt-n5">
                            <!-- <v-col cols="8" md="2">
                                <v-select class='text-caption' v-model="exenta" :items="fexenta" label="Factura Exenta" dense outlined :disabled="disabledExenta" :filled="filledExenta"></v-select>
                            </v-col> -->
                            <v-col cols="8" md="2">
                                <h5>Datos Contables</h5>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="text-caption" v-model="emision" label="Fec. Emision" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledExenta" :filled="filledExenta"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="emision" @input="menu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-select class='text-caption' v-model="forma_pago" :items="array_pago" label="Forma Pago" dense outlined :disabled="disabledExenta" :filled="filledExenta"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="ref_pago" label="Referencia" :disabled="disabledExenta" :filled="filledExenta" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-menu v-model="menu3" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="text-caption" v-model="vence_pago" label="Fec. Vencimiento" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledExenta" :filled="filledExenta"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="vence_pago" @input="menu3 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn v-if="existe=='si'" @click="saveFechas()" class="mt-1" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar Datos</span></v-btn>
                                <v-btn v-else class="mt-1" small color="grey" dark rounded disabled><v-icon small>save</v-icon><span class='text-btn'>Grabar Datos</span></v-btn>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="mt-n6">
                            <v-col cols="4" md="2" class="text-center">
                                <h5 class="mt-n3">Productos</h5>
                            </v-col>
                            <v-col cols="8" md="10">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row> -->
                        <v-row class="mt-n5">
                            <v-col cols="12" md="9">
                                <v-row>
                                    <v-col cols="8" md="3">
                                        <h5>Productos</h5>
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="10" md="4">
                                        <v-text-field v-if="radios!='Neto'" class='text-caption' v-model="nombre" label="Nombre Producto" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                        <v-combobox v-else class='text-caption' :hint="tStock" persistent-hint v-model="listanombre" :items="itemProductos" label="Selecciona Producto" dense outlined disabled filled></v-combobox>
                                    </v-col>
                                    <v-col cols="2" md="1">
                                        <v-btn v-if="radios!='Neto'" class="mt-1" small color="grey" dark rounded><v-icon small>find_replace</v-icon></v-btn>
                                        <v-btn v-else @click="inventa=true" class="mt-1" small color="indigo" dark rounded><v-icon small>find_replace</v-icon></v-btn>
                                    </v-col>
                                    <!-- <v-col cols="8" md="2">
                                        <v-text-field class='text-caption' v-model="oem" label="Cod. Proveedor" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col> -->
                                    <v-col cols="10" md="3">
                                        <v-select class='text-caption' v-on:change="OpenDialog(bodega)" v-model="bodega" :items="itemBodegas" label="Local" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>                                
                                    </v-col>
                                    <v-col cols="2" md="1">
                                        <v-icon @click="newLocals()" class="mt-2" color="indigo">add_circle_outline</v-icon>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-n5">
                                    <v-col cols="8" md="2">
                                        <v-text-field class='text-caption' @keypress="onlyDecimal" v-model="precio" label="Precio Neto" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="8" md="2">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="cantidad" label="Cantidad" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field class='text-caption' v-model="observa" label="Observacion" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-btn v-if="validaExenta!=null && validaExenta!=false"  small @click="saveCompra()" class="mt-1" color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar Producto</span></v-btn>
                                        <v-btn v-else class="mt-1" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar Producto</span></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-alert class='text-caption' dense text border="left" color="grey">
                                            <div class="float-left">
                                                Neto
                                            </div>
                                            <div class="float-right">
                                                ${{new Intl.NumberFormat("de-DE").format(parseFloat(neto))}}
                                            </div>                                             
                                        </v-alert>
                                        <v-alert class='mt-n2 text-caption' dense text border="left" color="grey">
                                            <div class="float-left">
                                                Iva ({{impuesto}}%)
                                            </div>
                                            <div class="float-right">
                                                ${{new Intl.NumberFormat("de-DE").format(parseFloat(iva))}}
                                            </div>                                             
                                        </v-alert>
                                        <v-alert style="height:40px;" class='mt-n2 text-body-2' dense text border="left" color="red">
                                            <div class="mt-1 float-left">
                                                Total
                                            </div>
                                            <div class="mt-1 float-right">
                                                ${{new Intl.NumberFormat("de-DE").format(parseFloat(subtotal))}}
                                            </div>                                             
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-45px">
                <v-btn @click="dialogCompras()" color="indigo" fab dark>{{nCompras}}</v-btn>
                <h3 class="ml-2 text-caption">Total Items</h3>
                <v-spacer></v-spacer>
                <v-btn @click="newCompra()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
            </v-card-actions>
        </v-card>
        <!-- Dialogo Bodega -->
        <v-dialog v-model="dialogBodega" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Nueva Bodega</v-toolbar>
            <v-card-text class="mt-5">
                <v-text-field class='text-caption' v-model="newbodega" label="Nombre Bodega" :disabled="disabledBodega" :filled="filledBodega" outlined dense required></v-text-field>
                <v-row>
                    <v-col cols="12" md="6">
                        <div v-if="alertabodega!='si'">
                            <v-btn @click="newBodega()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                        <div v-else>
                            <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-alert class='text-caption' v-if="alertabodega=='si'" dense text type="success">
                            Bodega Creada con Exito !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertabodega=='no'" dense text type="error">
                            No ha Ingresado Bodega !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Dato Obligatorio !!!
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="CloseDialog()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialogo tabla -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer></v-spacer>
                <a target="_blank" :href="html" style="text-decoration: none"><v-icon>print</v-icon></a>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion="{ item }">
                        <v-icon small @click="deleteCompra(item)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" class='mt-3'>
                        <a class="mt-2 ml-2" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img src="@/assets/csv.png" width="16px" height="20px"/></a>
                        <a class="ml-2" v-else :href="rutaCSV" style="text-decoration: none"><v-icon color='teal'>cloud_download</v-icon><span class="ml-2 text-caption">Descarga</span></a>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <!-- dialog inventa --> 
        <v-dialog v-model="inventa" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="inventa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer>
                    <div class="float-right">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="inventasearch" label="Buscar Producto" dense required></v-text-field>
                    </div>
                </v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleClick" dense dark :search="inventasearch" :headers="inventatitulo" :items="inventalista" :page.sync="inventapage" :items-per-page="inventaitemsPerPage"  @page-count="inventapageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="inventapage" :length="inventapageCount"  :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>
        <!-- DIALOGO NEW LOCAL -->
        <v-dialog v-model="dialogLocal" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Mantenedor de Locales</v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="newLocal" label="Nuevo Local" :disabled="disabledLocal" :filled="filledLocal" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-alert class='text-caption' v-if="alertaLocal=='si'" dense text type="success">
                            Local Creado !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertaLocal=='no'" dense text type="error">
                            Sin Local !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Dato Obligatorio !!!
                        </v-alert>
                    </v-col>
                    <v-col cols="12" md="2">
                        <div v-if="alertaLocal!='si'">
                           <v-btn @click="addLocals()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                            <!-- <v-btn @click="newCategoria()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                        </div>
                        <div v-else>
                            <v-btn small fab dark color="grey"><v-icon>playlist_add</v-icon></v-btn>
                            <!-- <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="12" md="12">
                        <v-data-table dense dark :headers="localHead" :items="localList" :page.sync="pageLocal" :items-per-page="itemsPerPageLocal"  @page-count="pageCountLocal = $event" hide-default-footer class="elevation-1 text-size">
                            <template v-slot:item.accion="{ item }">
                                <v-icon small class="white--text" @click="deleteLocal(item)">delete</v-icon>
                            </template>
                        </v-data-table>
                        <v-pagination v-model="pageLocal" :length="pageCountLocal" :total-visible="5"></v-pagination>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="CloseLocal()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Compra', 
    components:{dialogo, snack},  
    props: ['usu','contrato'], 
    data(){
        return{
            //contrato:'123',
            observa:'',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            existe:'',
            disabledOrden:false,
            filledOrden:false,
            disabledDatos:true,
            filledDatos:true,
            btnGrabar:true,
            disabledExenta:true,
            filledExenta:true,
            validaExenta:null,
            orden:'',
            itemProveedor:[],
            arrayProveedor:[],
            proveedor:'',
            listanombre:'',
            itemProductos:[],
            arrayProductos:[],
            bodega:'',
            itemBodegas:[],
            arrayBodegas:[],
            nombre:'',
            precio:'',
            cantidad:'',
            radios:'Neto',
            total:'0',            
            nProveedor:0,
            nProducto:0,
            nBodega:0,
            cv:'C',
            nCompras:0,
            // dialog Compras
            dialog:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
            headers: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Precio Neto', align: 'center', sortable: true, value: 'neto' },
                { text: 'Precio +iva', align: 'center', sortable: true, value: 'precio' },
                { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                { text: 'Accion', align: 'center', sortable: true, value: 'accion' },
            ],
            items: [],
            html:'',
            descargaCSV:false,
            rutaCSV:'',
            alertabodega:'',
            disabledBodega:false,
            filledBodega:false,
            newbodega:'',
            dialogBodega:false,
            rutProveedor:'',
            menu1:false,
            emision:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            emisionDefault:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            fexenta:['NO','SI'],
            exenta:'NO',
            inventa:false,
            inventasearch:'',
            inventatitulo:[
                { text: 'Sku', align: 'center', sortable: true, value: 'iSku', width:'' },
                { text: 'Producto', align: 'start', sortable: true, value: 'iProducto' },
            ],
            inventalista:[],
            inventapage: 1,
            inventapageCount: 0,
            inventaitemsPerPage: 10,
            tStock:'',
            //new
            array_pago:['Efectivo','Tarjeta','Transferencia','Cheque','Credito'],
            forma_pago:'',
            ref_pago:'',
            vence_pago:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu3:false,
            subtotal:0,
            oem:'',
            neto:0,
            iva:0,
            impuesto:0,
            // new local
            disabledLocal:false,
            filledLocal:false,
            dialogLocal:false,
            alertaLocal:'',
            newLocal:'',
            pageLocal: 1,
            pageCountLocal: 0,
            itemsPerPageLocal: 10,
            localHead:[
                { text: 'ID', align: 'center', sortable: false, value: 'id' },
                { text: 'Descripcion', align: 'start', sortable: false, value: 'name' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            localList:[],
        }
    },
    async mounted(){
        await this.buscaImpuesto();
        await this.buscaProveedor();
        await this.buscarProducto();
        await this.buscarBodegas()
    },
    methods:{
        async newLocals(){
            this.dialogLocal=true;
            this.alertaLocal='';
            this.newLocal='';
        },
        CloseLocal() {
           if(this.newLocal!='' && this.alertaLocal=='si'){
                this.bodega=this.newLocal;
                this.numeroBodega(this.bodega);
            };
            this.newLocal='';
            this.alertaLocal='';
            this.disabledLocal=false;
            this.filledLocal=false;
            this.dialogLocal=false;
        },
        async addLocals(){
            try {
                if(this.newLocal!=''){
                    this.disabledLocal=true;
                    this.filledLocal=true;
                    var parametros={
                        'contrato': this.contrato,
                        'nombre': this.newLocal};
                    const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/nueva',parametros);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos=='si'){
                        await this.buscarBodegas();
                        this.bodega=this.newLocal;
                        this.alertaLocal='si';
                    } else {
                        if (datos=='no'){
                            this.Msg='Local existe en el Historial';
                            this.msgbox=true;
                            this.color='red'
                            this.showSnack();
                        }
                    }
                } else {
                    this.alertaLocal='no';
                }                
            } catch (error) {
                console.log(error)
            }
        },
        async deleteLocal(item){
            try {
                //validar que la bodega no tenga stock
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/verify/'+this.contrato+'/'+item.id);
                const datos=response.data;
                if(datos[0].existe=='si'){
                    if (datos[0].Total!=0){
                        this.Msg='Bodega con Stock';
                        this.msgbox=true;
                        this.color='red'
                        this.showSnack();
                    } else {
                        //eliminar la bodega
                        const params={
                            'contrato':this.contrato,
                            'local':item.id,
                            'estado':'N'
                        }
                        const res = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/update/states/',params);
                        const dat=res.data;
                        if(dat=='si'){
                            await this.buscarBodegas();
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async buscaImpuesto(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/impuesto/'+this.contrato);
            const datos=response.data;
            this.impuesto=0;
            if (datos[0].existe=='si'){
                this.impuesto=datos[0].Iva
            }
        },
        handleClick(txtOpcion) {
            this.listanombre=txtOpcion.iProducto;
            this.tStock='SKU: '+txtOpcion.iSku;
            this.nProducto=txtOpcion.iSku;
            this.inventa=false;
        },
        // async confirmaExenta(){
        //     this.loading=true;
        //     if(this.emision!='0000-00-00'){
        //         if(this.exenta!=''){
        //             if(this.nCompras!=0){
        //                 await this.saveExenta();
        //             };
        //             this.disabledExenta=true;
        //             this.filledExenta=true;
        //             this.restoDatos(false);
        //         } else { 
        //             this.exenta='NO'
        //         }
        //     } else {
        //         this.emision=this.emisionDefault;
        //     }
        //     this.loading=false;
        // },
        // async saveExenta(){
        //     try {
        //         const params={
        //             'emision':this.emision,
        //             'exenta':this.exenta
        //         }
        //         const response = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/update/compra/exenta/'+this.contrato+'/'+this.orden+'/'+this.nProveedor,params);
        //         const datos=response.data;
        //         console.log(datos)
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
        // async verCompras(){
        //     try {
        //         const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/all/compras/'+this.contrato);
        //         const datos=response.data;
        //          if (datos){
        //              const Encabezado = datos.SetDTE.DTE.Documento.Encabezado;
        //              const Detalle = datos.SetDTE.DTE.Documento.Detalle;
        //              console.log(Detalle);
        //          } else {
        //              console.log('No existe');
        //          }
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
        async fileCSV(){
            this.loading=true;
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/fileCsv/compra/'+this.contrato+'/'+this.orden+'/'+this.nProveedor);
            const datos=response.data;
            //console.log(datos);
            if (datos=='si'){
                this.descargaCSV=true;
                this.rutaCSV='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+this.orden+'.csv';
            }
            this.loading=false;
        },
        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        onlyDecimal ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        dialogCompras(){
            if (this.nCompras!=0){
                this.dialog=true;
                this.descargaCSV=false;
                this.rutaCSV='';
                //this.datosDialog()
            }
        },
        async datosDialog(){
            try {
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/entidad/v4/'+this.cv+'/'+this.contrato+'/'+this.orden+'/'+this.nProveedor);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    //console.log(datos);
                    this.items=[];
                    this.neto=0;
                    this.html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/prt/compra/v4/'+this.contrato+'/'+this.orden+'/'+this.nProveedor;            
                    datos.forEach(element => {
                        this.neto=this.neto+(parseFloat(element.Neto)*parseFloat(element.Cantidad));
                        // this.subtotal=this.subtotal+(parseFloat(element.Precio)*parseFloat(element.Cantidad));
                        this.items.push({
                            'id':element.ID,
                            'sku':element.Sku,
                            'producto':element.Producto,
                            'neto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Neto)),
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                            'bodega':element.Bodega,
                            'numbodega':element.NumBodega
                        })        
                    });
                    // calculamos el neto y el iva
                    //let varNeto=parseInt(this.subtotal)/imp;
                    let varIva=(this.neto*this.impuesto)/100;
                    this.subtotal=Math.round(this.neto+varIva);
                    this.neto=parseFloat(this.neto).toFixed(2);
                    this.iva=parseFloat(varIva).toFixed(2);
                }                
            } catch (error) {
                console.log(error)
            }
        },
        async deleteCompra(linea){
            const n=linea.id;
            this.loading=true;
            const response = await axios.delete('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/entidad/delete/'+this.contrato+'/'+n);
            const datos=response.data;
            if (response.data=='ok'){
                await this.RebajaStock(linea);
                await this.datosDialog();
                this.nCompras=this.nCompras-1
            }           
            this.loading=false;
        },
        clsInput(){
            this.observa='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.btnGrabar=true;
            this.disabledExenta=true;
            this.filledExenta=true;
            this.validaExenta=null;
            this.radios='Neto';
            this.total='0';
            this.listanombre='';
            this.nombre='';
            this.precio='';
            this.cantidad='';
            this.nProveedor=0;
            this.nProducto=0;
            this.bodega='';
            this.nBodega=0;
            this.existe='';
            this.nCompras=0
            this.dialog=false;
            this.items=[];
            this.html='';
            this.descargaCSV=false;
            this.rutaCSV='';
            this.alertabodega='';
            this.newbodega='';
            this.dialogBodega=false;
            this.rutProveedor=''; 
            this.emision=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.exenta='NO';
            this.tStock='';
            this.inventasearch='';
            this.forma_pago='';
            this.ref_pago='';
            this.vence_pago=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.subtotal=0;
            this.iva=0;
            this.neto=0;
            this.oem='';
        },
        newCompra(){
            this.orden='';
            this.proveedor='';
            this.clsInput()
            this.restoDatos(true); 
        },
        async buscaProveedor(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/proveedores/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemProveedor=[];
            this.arrayProveedor=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemProveedor.push(element.Razon);
                    this.arrayProveedor.push({id:element.ID,razon:element.Razon,rutpro:element.Rut})        
                });
            }
        },
        async buscarProducto(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/productos/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemProductos=[];
            this.arrayProductos=[];
            this.inventalista=[];
            if (datos[0].existe=='si'){
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    this.itemProductos.push(element.Nombre);
                    this.arrayProductos.push({sku:element.Sku,nameSku:element.Nombre})                    
                    this.inventalista.push({'iSku':element.Sku,'iProducto':element.Nombre});
                }
                // datos.forEach(element => {
                //     this.itemProductos.push(element.Nombre);
                //     this.arrayProductos.push({sku:element.Sku,nameSku:element.Nombre})        
                // });
            }
        },
        async buscarBodegas(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemBodegas=[];
            //this.itemBodegas.push('Nueva');
            this.arrayBodegas=[];
            this.localList=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemBodegas.push(element.Nombre);
                    this.arrayBodegas.push({numBodega:element.ID,nameBodega:element.Nombre});
                    this.localList.push({'id':element.ID,'name':element.Nombre});      
                });
            }
        },
        // numeroSku(txtProducto){
        //     this.arrayProductos.forEach(element => {
        //         if (txtProducto==element.nameSku){
        //             this.nProducto=element.sku
        //         }
        //     });
        // },
        numeroProveedor(txtProveedor){
            this.arrayProveedor.forEach(element => {
                if (txtProveedor==element.razon){
                    this.nProveedor=element.id;
                    this.rutProveedor='Rut: '+element.rutpro
                }
            });
        },

        async numeroBodega(txtbodega){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+ this.contrato);
            const datos=response.data;
            datos.forEach(element => {
                if(element.Nombre==txtbodega){
                    this.nBodega=element.ID
                }
            });
        },
        // numeroBodega(txtBodega){
        //     this.arrayBodegas.forEach(element => {
        //         if (txtBodega==element.nameBodega){
        //             this.nBodega=element.numBodega
        //         }
        //     });
        // },
        async verOrden(nOrden,tProveedor){
            this.clsInput();
            this.loading=true;
            if (nOrden!=''){
                if (tProveedor.trim()!=''){
                    this.numeroProveedor(tProveedor);
                    const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/entidad/'+this.cv+'/'+this.contrato+'/'+nOrden+'/'+this.nProveedor);
                    const datos=response.data;
                    //console.log(datos);  
                    if (datos[0].existe=='si'){
                        this.nCompras=datos.length;
                        this.disabledOrden=true;
                        this.filledOrden=true;
                        this.disabledExenta=false;
                        this.filledExenta=false;
                        this.validaExenta=true;
                        this.emision=datos[0].Emision
                        if(datos[0].Exenta!=''){ this.exenta=datos[0].Exenta };                        
                        if(datos[0].Forma_Pago!=null){ this.forma_pago=datos[0].Forma_Pago };
                        if(datos[0].Referencia_Pago!=null){ this.ref_pago=datos[0].Referencia_Pago };
                        if(datos[0].Fecha_Pago!=null){ this.vence_pago=datos[0].Fecha_Pago };
                        this.restoDatos(false);
                        this.datosDialog();
                        this.existe='si'
                    }
                    if (datos[0].existe=='no'){
                        this.disabledOrden=true;
                        this.filledOrden=true;
                        this.disabledExenta=false;
                        this.filledExenta=false;
                        this.validaExenta=true;
                        this.restoDatos(false);
                        this.existe='no'                        
                    }
                    if (datos[0].existe=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newCompra()
                        this.existe=''                        
                    }
                } else {
                    this.Msg='Seleccione Proveedor'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
                    this.newCompra()
                    this.existe=''                        
                }
            } else {
                this.Msg='Ingrese Numero Compra'
                this.msgbox=true;
                this.color='info';
                this.showSnack();
                this.newCompra()
                this.existe=''                        
            }
            this.loading=false;
        },
        saveCompra(){
            if(!isNaN(this.precio)){
                this.precio=this.precio;
            } else {
                this.precio='';
            };
            this.loading=true;
            this.numeroBodega(this.bodega);
            if (this.impuesto!=0 && this.contrato!='' && this.orden!='' && this.proveedor!='' && this.bodega!='' &&
                this.cantidad!='' && this.precio!=''&& this.nProveedor!=0 && this.nBodega!=0 && this.forma_pago!=''){
                if (this.radios=='Bruto'){
                    if(this.nombre!=''){
                        this.listanombre='';
                        this.nProducto=0;
                        this.save()
                    } else { this.mensajeObligatorio() }
                }
                if (this.radios=='Neto'){
                    if(this.listanombre!=''){                        
                        //this.numeroSku(this.listanombre);
                        if (this.nProducto!=0){
                            this.nombre='';
                            this.save()
                        } else {
                            this.Msg='Inconsistencia, Intentelo nuevamente'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else { this.mensajeObligatorio() }
                } 
            } else {
                this.Msg='Revisar Datos Validos'
                this.msgbox=true;
                this.color='red';
                this.showSnack();                
            }
            this.loading=false;
        },
        mensajeObligatorio(){
            this.Msg='Todos los datos Obligatorios'
            this.msgbox=true;
            this.color='info';
            this.showSnack();
        },
        async save(){
            let nompro
            if(this.radio!='Bruto'){
                nompro=this.listanombre
            } else {
                nompro=this.nombre
            };
            // obtenemos el precio con iva
            const newIva=(parseFloat(this.precio)*parseFloat(this.impuesto))/100;
            const newPrecio=Math.round(parseFloat(this.precio)+newIva);
            var parametros={
                'contrato': this.contrato,
                'orden': this.orden,
                'entidad': this.nProveedor,
                'tipo': this.radios,
                'cv': this.cv,
                'sku':this.nProducto,
                'oems':this.oem,
                'producto':nompro,
                'neto':this.precio,
                'precio':newPrecio,
                'cantidad':this.cantidad,
                'bodega': this.nBodega,
                'observa':this.observa,
                'usuario':this.usu,
                'referencia':'0',
                'emision':this.emision,
                'exenta': this.exenta,
                'for_pago':this.forma_pago,
                'ref_pago':this.ref_pago,
                'fec_pago':this.vence_pago};
            const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/compraventa/nueva/v4',parametros);
            const datos=response.data;
            console.log(datos);
            if (datos=='si'){ 
                this.existe='si';               
                //actualizar stock de productos netos
                if(this.radio!='Bruto'){
                    var parametros1={
                        'contrato': this.contrato,
                        'sku': this.nProducto,
                        'cantidad': this.cantidad,
                        'signo': '0+',
                        'bodega': this.nBodega,
                        'usuario': this.usu,
                        'observa': this.observa,
                        'origen':'Compra'};
                    const response1 = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){
                        this.Msg='Producto Ingresado'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                        this.btnGrabar=false;
                        this.nCompras++;
                    } else {
                        this.Msg='Ingreso Exitosa, actualize el stock de forma manual'
                        this.msgbox=true;
                        this.color='warning';
                        this.showSnack();
                        this.btnGrabar=false;
                        this.nCompras++
                    }
                } else {
                    this.Msg='Producto Bruto Ingresado'
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                    this.btnGrabar=false;
                    this.nCompras++
                };
                // this.disabledExenta=true;
                // this.filledExenta=true;
                // this.validaExenta=true;
                this.clsItems();
                this.datosDialog();           
            } 
            if (datos=='error'){
                this.Msg='Conexion Inestable, intentelo mas tarde'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
                this.newCompra();
                this.existe=''  
            }         
        },
        async RebajaStock(linea){
            try {
                var param={
                    'contrato': this.contrato,
                    'sku': linea.sku,
                    'cantidad': linea.cantidad,
                    'signo': '0-',
                    'bodega': linea.numbodega,
                    'usuario': this.usu,
                    'observa': 'Elimina item Compra: '+this.orden,
                    'origen':'Ajuste'
                };
                const res = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock',param);
                const dat=res.data;
                if (dat=='si'){ 
                    console.log('Rebaja de Stock Exitosa');
                }                
            } catch (error) {
                console.log(error);                
            }
        },
        OpenDialog(a) {
            if(a=='Nueva'){
                this.dialogBodega=true;
                this.alertabodega='';
                this.newbodega='';
            } else {
                this.alertabodega='';
                this.newbodega='';
                this.dialogBodega=false;
                this.numeroBodega(a);
            }
        },
        CloseDialog() {
           if(this.newbodega!=''){
                this.bodega=this.newbodega;
                this.numeroBodega(this.bodega);
            } else {
                this.bodega='';
            }
            this.newbodega='';
            this.alertabodega='';
            this.disabledBodega=false;
            this.filledBodega=false;
            this.dialogBodega=false;
        },
        async newBodega(){
            if(this.newbodega!=''){
                this.disabledBodega=true;
                this.filledBodega=true;
                var parametros={
                    'contrato': this.contrato,
                    'nombre': this.newbodega};
                const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/nueva',parametros);
                const datos=response.data;
                this.itemBodegas.push(this.newbodega);
                this.bodega=this.newbodega;
                this.alertabodega='si';                
            } else {
                this.alertabodega='no';
            }
        },
        async saveFechas(){
            if(this.orden.trim()!='' && this.nProveedor!=0){
                var parametros={
                    'orden':this.orden,
                    'contrato':this.contrato,
                    'emision': this.emision,
                    'exenta':this.exenta,
                    'for_pago':this.forma_pago,
                    'ref_pago':this.ref_pago,
                    'fec_pago':this.vence_pago};
                const response = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/compra/contable/'+this.nProveedor,parametros);
                const datos=response.data;
                if(datos=='si'){
                    this.Msg='Datos contables aceptados'
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                    // this.disabledExenta=true;
                    // this.filledExenta=true;
                    // this.validaExenta=true
                    //this.restoDatos(false);
                } else {
                    this.Msg='Datos NO actualizados'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            }
        },
        clsItems(){
            this.listanombre='';
            this.nombre='';
            this.tStock='';
            this.oem='';
            this.bodega='';
            this.precio='';
            this.cantidad='';
            this.observa=''
        },

    }
}
</script>