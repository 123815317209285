<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Balance Cartera</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Balance Cartera</h3> <!-- Mobile Screen -->
    <div v-if="vista===true" class="mt-5">
    <v-card>
      <v-card-subtitle class="blue-grey white--text">Indicadores de Crecimiento</v-card-subtitle>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="12" md="6">
              <CarteraVentas :usu="usu" :contrato="contrato"/>
          </v-col>
          <v-col cols="12" md="6">
              <UtilidadMes :usu="usu" :contrato="contrato"/>
          </v-col>
          <v-col cols="12" md="6">
              <ChartVentas :usu="usu" :contrato="contrato"/>
          </v-col>
          <v-col cols="12" md="6">
              <UtilidadVentas :usu="usu" :contrato="contrato"/>
              <ChartCrece :usu="usu" :contrato="contrato"/>
         </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    </div>
    <div v-else-if="vista===false" class="mt-5">
      <v-card>
        <v-card-subtitle><v-icon class="red--text">cancel</v-icon><span class="ml-2">Solicitud Denegada</span></v-card-subtitle>
      </v-card>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>
<script>
import axios from 'axios';
import CarteraVentas from '@/components/CarteraVentas.vue';
import ChartVentas from '@/components/ChartVentas.vue';
import UtilidadMes from '@/components/UtilidadMes.vue';
import UtilidadVentas from '@/components/UtilidadVentas.vue';
import ChartCrece from '@/components/ChartCrece.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Cartera',
  components: { CarteraVentas, ChartVentas,UtilidadMes,UtilidadVentas,ChartCrece ,dialogo},
  props:['usu','contrato'],
  data(){
    return{
      loading:false,
      vista:null
    }
  },
  async mounted(){
    await this.entrada();
  },
  methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      //console.log(datos);
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_5_1!=0) {
          this.vista=true;
        } else { this.vista=false; }
      } else { this.vista=false; }
      this.loading=false;
    }
  }
}
</script>