<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">EXPRESS</v-col>
                    <v-col cols="6" md="6">
                        <v-row class="mt-0 mr-2" justify="end">
                            <span class="text-caption"><v-chip color="white"><strong class="text-body-2">{{txtSucursal}}</strong></v-chip></span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row class="mt-1">
                    <v-col cols="12" md="8">
                        <v-row>
                            <v-col cols="6" md="8">
                                <v-text-field style="width:130px" id="barr"  label="Codigo Barra"
                                  v-on:change="captureBarra(barra)" class='text-caption' 
                                  @keypress="onlyNumber" v-model="barra" dense outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" md="4" class="mt-1 text-right">
                                Productos
                                <v-btn class="ml-1" @click="inventa=true" small color="indigo" dark rounded><v-icon small>find_replace</v-icon></v-btn>                                
                            </v-col>
                        </v-row>
                        <v-row class="mt-n10">
                            <v-col cols="12" md="12">
                                <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                    <template v-slot:item.cantidad="{ item }">
                                        <v-edit-dialog @save="save(item,editCantidad)" @cancel="cancel()" @close="close()" @open="open()">
                                            <v-icon small class="ml-1 blue--text">edit</v-icon>
                                            <span class="ml-1">{{item.cantidad}}</span>
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">Cantidad</div>
                                                <v-text-field @keypress="onlyNumber" v-model="editCantidad" single-line></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:item.menor="{ item }">
                                        <v-icon class="ml-1 red--text" @click="menos(item)">arrow_drop_down</v-icon>
                                    </template>
                                    <template v-slot:item.accion="{ item }">
                                        <v-icon small @click="deleteFila(item)" color="red">delete</v-icon>
                                    </template>
                                    <template v-slot:item.precio="{ item }">
                                        <v-edit-dialog @save="savePrecio(item,editPrecio)" @cancel="cancel()" @close="close()" @open="open()">
                                            <v-icon small class="ml-1 blue--text">edit</v-icon>
                                            <span class="ml-1">{{item.precio}}</span>
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">Precio</div>
                                                <v-text-field @keypress="onlyNumber" v-model="editPrecio" single-line></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                </v-data-table>
                                <v-pagination v-model="page" :length="pageCount" :total-visible="5"></v-pagination>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-row>
                            <v-col cols="12" md="12">
                                <div v-if="ntipoDte==22">
                                    <v-row>
                                        <v-col cols="6" md="6">
                                            <v-btn block color="teal" dark>Boleta</v-btn>
                                        </v-col>
                                        <v-col cols="6" md="6">
                                            <v-btn @click="cualDte(5)" block color="grey" outlined>Factura</v-btn>                                
                                        </v-col>
                                    </v-row>
                                </div>
                                <div v-if="ntipoDte==5">
                                    <v-row>
                                        <v-col cols="6" md="6">
                                            <v-btn @click="cualDte(22)" block color="grey" outlined>Boleta</v-btn>                                
                                        </v-col>
                                        <v-col cols="6" md="6">
                                            <v-btn @click="cualDte(5)" block color="teal" dark>Factura</v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" >
                                <v-text-field class='text-caption' v-model="clienteExpress" label="Cliente" dense outlined filled disabled hint="rutExpress"></v-text-field>
                                <div class="mt-n7 ml-3"><span class="text-caption grey--text">{{rutExpress}}</span></div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n2">
                            <v-col cols="12" md="12" >
                                <v-text-field class='text-caption' v-model="envio" label="Envio Digital" dense outlined></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="12" md="5" >
                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="text-caption" v-model="vencimiento" label="Ven." prepend-icon="event" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-model="vencimiento" @input="menu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="7" >
                                <v-select class='text-caption' v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" item-value="id" outlined dense></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="mt-n5">
                <v-row>
                    <v-col cols="12" md="8">
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-card color="blue-grey" dark>
                                    <v-card-title>
                                        <h6>NETO</h6>
                                        <v-spacer></v-spacer>
                                        <h5>$ {{new Intl.NumberFormat("de-DE").format(parseFloat(totalNeto))}}</h5>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-card color="blue-grey" dark>
                                    <v-card-title>
                                        <h6>IVA ({{impuesto}}%)</h6>
                                        <v-spacer></v-spacer>
                                        <h5>$ {{new Intl.NumberFormat("de-DE").format(parseFloat(totalIva))}}</h5>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-card color="blue-grey" dark>
                                    <v-card-title>
                                        <!-- <h6>TOTAL (<span style="font-size:7.5pt">Ajuste ${{totalAjuste}}</span>)</h6> -->
                                        <h6>TOTAL</h6>
                                        <v-spacer></v-spacer>
                                        <h5>$ {{new Intl.NumberFormat("de-DE").format(parseFloat(totalVenta))}}</h5>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4" class="mt-7 text-right">
                        <v-btn @click="nuevaVenta()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                        <v-btn v-if="btnGrabar===true" @click="enviarDTE()" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                        <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                        <a v-if="btnPrt===true" target="_blank" :href="html" class="ml-2 mr-2"><v-icon color="teal">print</v-icon></a>
                        <a v-else href="javascript:void(0)" class="ml-3 mr-2"><v-icon class="grey--text">print</v-icon></a>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <!-- dialog inventa --> 
        <v-dialog v-model="inventa" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="6" md="3">
                        <v-btn @click="inventa=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
                    </v-col>
                    <v-col cols="6" md="5">
                        <!-- <h6>Bodega : {{txtBodega}}</h6>                 -->
                    </v-col>
                    <v-col cols="12" md="4" class="text-right">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="inventasearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleProductos" dense dark :search="inventasearch" :headers="inventatitulo" :items="inventalista" :page.sync="inventapage" :items-per-page="inventaitemsPerPage"  @page-count="inventapageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="inventapage" :length="inventapageCount" :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>
        <!-- dialog clientes --> 
        <v-dialog v-model="clientes" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="clientes=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
                <v-spacer>
                    <div class="float-right">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="clientesearch" label="Buscar Cliente" dense required></v-text-field>
                    </div>
                </v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleClientes" dense dark :search="clientesearch" :headers="clientetitulo" :items="clientelista" :page.sync="clientepage" :items-per-page="clienteitemsPerPage"  @page-count="clientepageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="clientepage" :length="clientepageCount" :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Venta', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            clienteExpress:'Boleta DTE',
            rutExpress:'1-9',
            ntipoDte:22,  // (22) Boleta ; (5) Factura
            dialogoPago:false,
            orden:'',
            headers: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku', width:'15%' },
                { text: 'Producto', align: 'start', sortable: false, value: 'producto', width:'50%' },
                { text: 'Cantidad', align: 'end', sortable: false, value: 'cantidad', width:'14%' },
                { text: '', align: 'start', sortable: false, value: 'menor', width:'1%' },
                { text: 'Precio', align: 'end', sortable: false, value: 'precio', width:'15%' },
                { text: '', align: 'center', sortable: false, value: 'accion', width:'5%' },
            ],
            items: [],
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            itemDTE:[
                {id:'22',doc:'Boleta'},
                {id:'5',doc:'Factura'},
            ],            
            inventa:false,
            inventasearch:'',
            inventatitulo:[
                { text: 'Sku', align: 'center', sortable: true, value: 'sku', width:'' },
                { text: 'Producto', align: 'start', sortable: true, value: 'nameSku' },
                { text: 'Stock', align: 'center', sortable: true, value: 'stockSku' },
            ],
            inventalista:[],
            inventapage: 1,
            inventapageCount: 0,
            inventaitemsPerPage: 10,
            clientes:false,
            clientesearch:'',
            clientetitulo:[
                { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'nombre' },
            ],
            clientelista:[],
            clientepage: 1,
            clientepageCount: 0,
            clienteitemsPerPage: 10,

            totalVenta:0,
            totalNeto:0,
            totalAjuste:0,
            totalIva:0,

            btnGrabar:true,
            btnPrt:false,
            itemPAGO:[],
            barra:'',
            html:'',

            entidad:'',
            entidad_boleta:'',
            rut:'',
            razon:'',
            direccion:'',
            comuna:'',
            ciudad:'',
            giro:'',
            fecha:'',
            hoy:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            neto:0,
            impuesto:'',
            iva:0,
            total:0,
            dte:'',
            pago:'',
            
            OutID:0,
            descargaCSV:false,
            rutaCSV:'',   
            envio:'',
            nStock:0,
            txtStock:'si',
            nSucursal:0,
            txtSucursal:'',
            menu1:false,
            vencimiento:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            vendedor:0,
            nBodega:0,
            txtBodega:'Sin Bodega',
            editCantidad:'',
            idLocal:0,
            editPrecio:'',
        }
    },
    async mounted(){
        await this.buscaImpuesto();
        await this.focusBarra();
        this.loading=true;
        await this.buscaLocal();
        //await this.buscarSucursal();
        await this.newVenta();
        await this.cargaPagos('1-9');
        this.loading=false;
    },
    methods:{
        async buscaImpuesto(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/impuesto/'+this.contrato);
            const datos=response.data;
            this.impuesto=0;
            if (datos[0].existe=='si'){
                this.impuesto=datos[0].Iva
            }
        },
        async buscaLocal(){
            try {
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.idLocal=datos[0].Bodega;
                    this.nSucursal=this.idLocal;
                    this.vendedor=datos[0].ID;
                    this.nBodega=datos[0].Bodega;
                    const sucu=await this.buscarSucursal_v2();
                    if(sucu!=''){
                        this.txtSucursal=sucu;
                        this.txtBodega=sucu
                    } else {
                        this.txtSucursal=this.nSucursal;
                        this.txtBodega=this.nSucursal
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async buscarSucursal_v2(){
            let res = '';
            try {
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        if(element.ID==this.nSucursal){
                            res = element.Nombre
                        };
                    });
                }                
            } catch (error) {
                console.log(error)
            };
            return res
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        cualDte(nDte){
            if(nDte==22){
                this.clienteExpress='Boleta DTE';
                this.rutExpress='1-9';
                this.entidad=this.entidad_boleta;
                this.envio='';
                this.ntipoDte=22;
                const timestamp = Date.now();
                this.orden=this.vendedor+''+timestamp.toString();
                //console.log(this.orden);
                this.cargaPagos(this.rutExpress);
            };
            if(nDte==5){
                this.clienteExpress='';
                this.rutExpress='.';
                this.envio='';
                this.entidad='';
                this.ntipoDte=5;
                this.clientes=true;
                const timestamp = Date.now();
                this.orden=this.vendedor+''+timestamp;
                //console.log(this.orden);
            };

        },
        async buscarProducto(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock/datatable/'+this.contrato);
            const datos=response.data;
            // const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/productos/'+this.contrato);
            // const datos=response.data;
            this.inventalista=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    if(parseInt(element.Total)>=1 && parseInt(element.numBodega)==parseInt(this.nBodega)){
                        this.inventalista.push({
                            sku:element.Sku,
                            barraSku:element.Barra,
                            nameSku:element.Nombre,
                            precioSku:element.PrecioLocal,
                            stockSku:element.Total
                        });                    
                    }
                });
            }
        },
        handleProductos(fila) {
            //buscamos sku en la lista
            let esta='no';
            let imp=1+(this.impuesto/100);
            let precio_item=0;
            if(this.items.length!=0){
                this.items.forEach(element => {
                    if (element.sku==fila.sku){
                        esta='si';
                        element.cantidad=parseInt(element.cantidad)+1;
                        precio_item=parseInt(element.valor);
                        // element.valor=parseInt(element.valor)+parseInt(fila.precioSku);
                        // element.precio=new Intl.NumberFormat("de-DE").format(parseFloat(element.valor));
                    };
                });
            };
            if(esta=='no'){
                let unidad=fila.precioSku/imp;
                this.items.push({
                    sku:fila.sku,
                    producto:fila.nameSku,
                    precio:new Intl.NumberFormat("de-DE").format(parseFloat(fila.precioSku)),
                    valor:parseInt(fila.precioSku),
                    unidad:unidad,
                    cantidad:1,
                    bodega:this.nBodega
                });
                precio_item=parseInt(fila.precioSku);
            };
            // this.totalNeto=this.totalNeto+parseInt(fila.precioSku);
            // this.totalIva=Math.round(((parseInt(this.totalNeto)*19)/100),0);
            // this.totalVenta=parseInt(this.totalNeto)+parseInt(this.totalIva);
            this.totalVenta=this.totalVenta+precio_item;
            this.totalNeto=this.totalVenta/imp;
            this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
            this.totalIva=(this.totalNeto*this.impuesto)/100;
            this.totalIva=parseFloat(this.totalIva).toFixed(2);
            this.totalAjuste=this.totalVenta-(this.totalNeto+this.totalIva);
            this.totalAjuste=0;
            this.inventa=false;
        },
        async buscaCliente(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/lista/clientes/'+this.contrato);
            const datos=response.data;
            this.clientelista=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    if(element.Rut==this.rutExpress){ 
                        this.entidad_boleta=element.ID;
                        this.entidad=this.entidad_boleta; 
                    };
                    this.clientelista.push({
                        entidad:element.ID,
                        nombre:element.Nombre,
                        rut:element.Rut,
                        email:element.Email,
                        direccion:element.Direccion,
                        comuna:element.Comuna,
                        ciudad:element.Ciudad,
                        giro:element.Observacion,
                    })        
                });
            }
        },
        handleClientes(fila) {
            this.clienteExpress=fila.nombre;
            this.rutExpress=fila.rut;
            this.envio=fila.email;
            this.entidad=fila.entidad;
            this.rut=fila.rut;
            this.razon=fila.nombre;
            this.direccion=fila.direccion;
            this.comuna=fila.comuna;
            this.ciudad=fila.ciudad;
            this.giro=fila.giro;
            this.clientes=false;
            this.cargaPagos(this.rutExpress);
        },
        // async buscarSucursal(){
        //     const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
        //     const datos=response.data;
        //     if (datos[0].existe=='si'){
        //         if (datos[0].Sucursal!=0){
        //             this.nSucursal=datos[0].Sucursal;
        //             this.vendedor=datos[0].ID;
        //             this.nBodega=datos[0].Bodega;
        //             //buscar nombre de la bodega
        //             const response0 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/bodegas/'+this.contrato);
        //             const datos0=response0.data;
        //             if (datos0[0].existe=='si'){
        //                 datos0.forEach(element => {
        //                     if(element.ID==this.nBodega){
        //                         this.txtBodega=element.Nombre                            
        //                     }
        //                 });
        //             };
        //             //buscar nombre de la sucursal
        //             const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/sucursales/'+this.contrato);
        //             const datos1=response1.data;
        //             if (datos1[0].existe=='si'){
        //                 datos1.forEach(element => {
        //                     if(element.ID==this.nSucursal){
        //                         this.txtSucursal=element.Nombre                            
        //                     }
        //                 });
        //             } else {  
        //                 this.txtSucursal='Sin Sucursal';
        //                 this.nSucursal=0;
        //             }
        //         } else {
        //             this.txtSucursal='Sin Sucursal';
        //             this.nSucursal=0;
        //         }
        //     } else { 
        //         this.txtSucursal='Sin Sucursal';
        //         this.nSucursal=0;
        //     }
        // },
        async cargaPagos(xRut){
            try {
                var auth='NO';
                const res = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/clientes/'+this.contrato+'/'+ xRut);
                const dat=res.data;
                if (dat[0].existe=='si'){ auth=dat[0].Credito };            
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/forma/pago/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.itemPAGO=[];
                    datos.forEach(element => {
                        if(element.CodigoBsale=='4'){
                            if(auth=='SI'){
                                this.itemPAGO.push({
                                    'id':element.CodigoBsale,
                                    'forma':element.Descripcion
                                })
                            }
                        } else {
                            this.itemPAGO.push({
                                'id':element.CodigoBsale,
                                'forma':element.Descripcion
                            })
                        }
                    });
                }                
            } catch (error) {
                console.log(error)
            }
            // const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/forma/pago/'+this.contrato);
            // const datos=response.data;
            // if (datos[0].existe=='si'){
            //     this.itemPAGO=[];
            //     datos.forEach(element => {
            //         this.itemPAGO.push({
            //             'id':element.CodigoBsale,
            //             'forma':element.Descripcion
            //         })
            //     });
            // }
        },
        captureBarra(codBarra){
            //console.log(codBarra);
            const resultado = this.inventalista.find( datos => datos.barraSku === codBarra );
            if(resultado){
                this.handleProductos(resultado);
            } else {
                //console.log('Producto no Existe');
                this.Msg='Producto no Existe'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            };
            this.focusBarra();
        },
        async focusBarra(){
            var elemento = document.getElementById("barr");
            elemento.focus();
            this.barra='';
            //elemento.blur();
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        async nuevaVenta(){
            console.log(this.items);
            this.loading=true;
            await this.newVenta();
            this.loading=false;
        },
        async newVenta(){
            this.btnGrabar=true;
            this.btnPrt=false;
            this.hoy=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.vencimiento=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.pago='';
            this.rut='';
            this.entidad='';
            this.razon='';
            this.direccion='';
            this.comuna='';
            this.ciudad='';
            this.giro='';
            this.totalNeto=0;
            this.totalAjuste=0;
            this.totalIva=0;
            this.totalVenta=0;
            this.items=[];
            this.clienteExpress='Boleta DTE';
            this.rutExpress='1-9';
            this.envio='';
            this.ntipoDte=22;
            const timestamp = Date.now();
            this.orden=this.vendedor+''+timestamp.toString();
            //Refrescar tablas
            await this.buscarProducto();
            await this.buscaCliente();
        },
        async enviarDTE(){
            this.loading=true;
            //console.log(this.entidad);
            if(this.nSucursal!=0){
                if(this.totalNeto!=0 && this.totalIva!=0 && this.totalVenta!=0){
                    if(this.rutExpress!='.'){
                        if(this.pago!=''){
                            const stateCotiza=await this.saveCotiza();
                            if(stateCotiza){
                                var sigla=this.contrato.substring(0,3);
                                //console.log(sigla);
                                if (sigla=='INV') {
                                    await this.saveDTE();
                                } else {
                                    if (sigla=='NDV'){
                                        await this.outDTE();
                                    } else {
                                        this.Msg='Error de Contrato'
                                        this.msgbox=true;
                                        this.color='red';
                                        this.showSnack();
                                    }
                                }
                            } else {
                                this.Msg='Inconsistencia en datos'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                            this.btnGrabar=false;
                            //this.btnPrt=true;
                        } else {
                            this.Msg='Seleccione Forma de Pago'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else {
                            this.Msg='Seleccione Cliente'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                    }
                } else {
                    this.Msg='Sin Total para vender'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Vendedor sin Sucursal'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            };
            this.loading=false;
        },
        async saveCotiza(){
            let todos='si';
            if(this.items.length!=0){
                for (let index = 0; index < this.items.length; index++) {
                    const element = this.items[index];
                    var parametros={
                        'contrato': this.contrato,
                        'orden': this.orden,
                        'entidad': this.entidad,
                        'tipo': 'Neto',
                        'cv': 'V',
                        'sku':element.sku,
                        'producto':element.producto,
                        'precio':element.valor,
                        'cantidad':element.cantidad,
                        'bodega': element.bodega,
                        'observa':'',
                        'usuario':this.usu,
                        'referencia':'0'};
                    const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/compraventa/nueva',parametros);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos!='si'){ 
                        todos='no'
                    }
                };
                if(todos!='si'){
                    // eliminar cotiza
                    const response = await axios.delete('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/orden/delete/'+this.contrato+'/'+n);
                    const datos=response.data;
                    return false;
                } else {
                    console.log('Cotizacion Grabada');
                    return true
                }
            } else {
                return false
            }
        },
        async outDTE(){
            const selectDTE=this.ntipoDte;
            const selectPago=this.pago;
            if (parseInt(this.totalVenta)!=0){
                if (selectDTE!=0 && selectPago!=0){
                    this.loading=true;
                    this.btnGrabar=false;
                    this.OutID=0;
                    //obtener ID nuevo del dte
                    const params={
                        'contrato':this.contrato,
                        'orden':this.orden,
                        'estado':'V',
                        'usuario':this.usu
                    };
                    try {
                        const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/id/new/v2/',params);
                        const res = response.data;
                        if (res=='si'){
                            const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/id/new/v2/'+this.contrato+'/'+this.orden+'/'+this.usu);
                            const res1 = response1.data;
                            if (res1[0].existe=='si'){
                                this.OutID=parseInt(res1[0].Folio); 
                            }                            
                        };
                        if (this.OutID!=0){
                            //console.log(this.OutID);
                            //confirmar que no existe en DTE
                            const response2 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/one/'+this.contrato+'/'+this.OutID);
                            const res2 = response2.data;
                            if (res2[0].existe=='no'){ 
                                this.html='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/prt/venta/v4/'+this.contrato+'/'+this.OutID+'/'+this.entidad;
                                const paramsDTE={
                                    'contrato':this.contrato,
                                    'numero':this.OutID,
                                    'tipoDte':selectDTE,
                                    'tipoPago':selectPago,
                                    'neto':this.totalNeto,
                                    'iva':this.totalIva,
                                    'total':this.totalVenta,
                                    'fecha':this.hoy,
                                    'vencimiento':this.vencimiento,
                                    'orden':this.orden,
                                    'rutaPDF':this.html,
                                    'rutaXML':this.html,
                                    'usuario':this.usu,
                                    'sucursal':this.nSucursal
                                };     
                                await this.baseDTE(paramsDTE);
                            } else {
                                this.Msg='No Existen Folios'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        } else {
                            this.Msg='Folio No Asignado'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    this.Msg='Seleccione DTE y Pago'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Sin Productos'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },        
        async saveDTE(){
            const selectDTE=this.ntipoDte;
            const selectPago=this.pago;
            if (selectDTE!=0 && selectPago!=0){                
                // obtenemos el token del contrato
                let valToken='';
                const resToken = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/token/access/'+this.contrato);
                const respuesta = resToken.data;
                if (respuesta[0].existe=='si'){ 
                    valToken=respuesta[0].Token;
                } else { console.log('Token Invalido') }
                // fecha cotizacion formato Bsale
                let intFecha=Date.parse(this.hoy);
                let strFecha=intFecha.toString();
                strFecha=strFecha.substring(0,10);
                const numFecha=parseInt(strFecha);
                // fecha actual formato Bsale
                let intHoy=Date.parse(this.hoy);
                let strHoy=intHoy.toString();
                strHoy=strHoy.substring(0,10);
                const numHoy=parseInt(strHoy);
                // fecha vencimiento formato Bsale
                let intVence=Date.parse(this.vencimiento);
                let strVence=intVence.toString();
                strVence=strVence.substring(0,10);
                const numVence=parseInt(strVence);
                // creamos json Bsale
                let pCliente={
                    "code": this.rut,
                    "city": this.ciudad,
                    "company": this.razon,
                    "municipality": this.comuna,
                    "activity": this.giro,
                    "address": this.direccion
                };
                let pDetalle=[];
                this.items.forEach(element => {
                    pDetalle.push({
                        //"variantId":1,
                        "netUnitValue":parseFloat(element.unidad).toFixed(4),
                        "quantity": parseInt(element.cantidad),
                        //"taxId": "[1,2]",
                        "comment": element.producto,
                        //"discount": 0,
                        "taxes": [
                            {
                            "code": 14,
                            "percentage": 19
                            }
                        ]
                    })        
                });
                let pPago=[
                    {
                        "paymentTypeId": selectPago,
                        "amount": this.totalVenta,
                        "recordDate": numHoy
                    }
                ];
                let pReferencia=[
                    {
                        "number": this.orden,
                        "referenceDate": numFecha,
                        "reason": "Orden de Compra "+this.orden,
                        "codeSii": 801
                    }
                ];
                //console.log(selectDTE);
                var emitDTE;
                if(selectDTE!=22){ // factura
                    emitDTE=
                    {
                        "documentTypeId": selectDTE,
                        "officeId": 1,
                        "emissionDate": numHoy,
                        "expirationDate": numVence,
                        "declareSii": 1,
                        //"priceListId": 18,
                        "client": pCliente,
                        "details": pDetalle,
                        "payments": pPago,
                        "references": pReferencia
                    }
                } else { // boleta
                    emitDTE=
                    {
                        "documentTypeId": selectDTE,
                        //"documentTypeId": 1,
                        "officeId": 1,
                        "emissionDate": numHoy,
                        "expirationDate": numHoy,
                        "declareSii": 1,
                        //"priceListId": 18,
                        "details": pDetalle
                        //"payments": pPago,
                    }
                }
                // consumir api Bsale para crear DTE
                let resDTE =[];
                //console.log(valToken);
                //console.log(emitDTE);
                try {
                    const response = await axios.post('https://api.bsale.cl/v1/documents.json', emitDTE , {headers : {"Accept":"application/json","content-type":"application/json","access_token":valToken}});
                    resDTE = response.data;
                    //console.log(resDTE);
                } catch (error) {
                    console.log (error);
                }
                if (resDTE.length!=0){
                    this.envioDTE(this.envio,resDTE.urlPdf,resDTE.urlXml);
                    this.html=resDTE.urlPdf;
                    this.btnPrt=true;
                    //this.btnGrabar=false;
                    this.OutID=0;
                    this.OutID=resDTE.number;                    
                    const paramsDTE={
                        'contrato':this.contrato,
                        'numero':resDTE.number,
                        'tipoDte':selectDTE,
                        'tipoPago':selectPago,
                        'neto':this.totalNeto,
                        'iva':this.totalIva,
                        'total':this.totalVenta,
                        'fecha':this.hoy,
                        'vencimiento':this.vencimiento,
                        'orden':this.orden,
                        'rutaPDF':resDTE.urlPdf,
                        'rutaXML':resDTE.urlXml,
                        'usuario':this.usu,
                        'sucursal':this.nSucursal
                    };                    
                    await this.baseDTE(paramsDTE);
                } else {
                    this.Msg='DTE no enviado'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
                //console.log(res);
            } else {
                this.Msg='Seleccione DTE y Pago'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async baseDTE(parametrosDTE){
            const response = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/DTE/nueva',parametrosDTE);
            const datos=response.data;
            //console.log(parametrosDTE);
            if (datos=='si'){ 
                await this.updateReferencia();
            } else {
                this.Msg='DTE enviado sin registro Local'
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            }
        },
        async updateReferencia(){
            var parametros0={'numero': this.OutID};
            const response0 = await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/referencia/actualiza/'+this.contrato+'/'+this.orden+'/'+this.entidad,parametros0);
            const datos0=response0.data;
            //console.log(datos0);
             if (datos0=='si'){
                await this.UpdateStock();
             } else {
                this.Msg='DTE enviado sin Referencia';
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
             }
        },
        async UpdateStock(){
            let t=0;
            if (this.items.length!=0) {
                for (var i = 0; i < this.items.length; i++) {
                    var parametros1={
                            'contrato': this.contrato,
                            'sku': this.items[i].sku,
                            'cantidad': this.items[i].cantidad,
                            'signo': '0-',
                            'bodega': this.items[i].bodega,
                            'usuario': this.usu,
                            'observa': '',
                            'origen':'Venta'};
                    const response1 = await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){ t++ }
                };
                if (t!=this.items.length) {
                    this.Msg='DTE enviado sin actualizar Stock';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } else {
                    this.btnPrt=true;
                    this.Msg='DTE exitoso';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                }
            }
        },
        async fileCSV(){
            if (this.btnPrt===true){
                this.loading=true;
                const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/fileCsv/venta/'+this.contrato+'/'+this.OutID+'/'+this.entidad);
                const datos=response.data;
                //console.log(datos);
                if (datos=='si'){
                    this.descargaCSV=true;
                    this.rutaCSV='http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/buzon/'+this.contrato+'/'+this.OutID+'.csv';
                }
                this.loading=false;
            }
        },        
        async envioDTE(datoEnvio,datoPDF,datoXML){
            if (datoEnvio.trim()!=''){
                let InstFormData = new FormData();
                InstFormData.append('email' , datoEnvio);
                InstFormData.append('pdf' , datoPDF);
                InstFormData.append('xml' , datoXML);
                const response= await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/server/sender', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                //console.log(response.data)
            }
        },
        async deleteFila(fila){
            this.items = this.items.filter(function(dat) {
                 return dat.sku !== fila.sku; 
            });
            let imp=1+(this.impuesto/100);            
            this.totalVenta=this.totalVenta-(parseInt(fila.valor)*parseInt(fila.cantidad));
            this.totalNeto=this.totalVenta/imp;
            this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
            this.totalIva=(this.totalNeto*this.impuesto)/100;
            this.totalIva=parseFloat(this.totalIva).toFixed(2);
            // this.totalNeto=parseInt(this.totalNeto)-(parseInt(fila.valor)*parseInt(fila.cantidad));
            // this.totalIva=Math.round(((parseInt(this.totalNeto)*19)/100),0);
            // this.totalVenta=parseInt(this.totalNeto)+parseInt(this.totalIva);
            this.focusBarra();
        },
        async menos(fila){
            let imp=1+(this.impuesto/100);            
            fila.cantidad=parseInt(fila.cantidad)-1;
            this.totalVenta=this.totalVenta-parseInt(fila.valor);
            this.totalNeto=this.totalVenta/imp;
            this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
            this.totalIva=(this.totalNeto*this.impuesto)/100;
            this.totalIva=parseFloat(this.totalIva).toFixed(2);
            this.totalAjuste=this.totalVenta-(this.totalNeto+this.totalIva);
            this.totalAjuste=0;
            // this.totalNeto=parseInt(this.totalNeto)-parseInt(fila.valor);
            // this.totalIva=Math.round(((parseInt(this.totalNeto)*19)/100),0);
            // this.totalVenta=parseInt(this.totalNeto)+parseInt(this.totalIva);
            if(parseInt(fila.cantidad)==0){
                this.items = this.items.filter(function(dat) {
                    return dat.sku !== fila.sku; 
                });
            };
             this.focusBarra();
        },
        savePrecio(fila,newPrecio){
            if(newPrecio!=''){
                if(parseInt(newPrecio)!=0){
                    let imp=1+(this.impuesto/100);
                    let newUnidad=newPrecio/imp;
                    const resta=(parseInt(fila.valor)*parseInt(fila.cantidad));
                    this.totalVenta=this.totalVenta-parseInt(resta);
                    const suma=(parseInt(newPrecio)*parseInt(fila.cantidad));
                    this.totalVenta=parseInt(this.totalVenta)+parseInt(suma);
                    fila.valor=newPrecio;
                    fila.precio=new Intl.NumberFormat("de-DE").format(parseFloat(newPrecio));
                    fila.unidad=newUnidad;
                    this.totalNeto=this.totalVenta/imp;
                    this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
                    this.totalIva=(this.totalNeto*this.impuesto)/100;
                    this.totalIva=parseFloat(this.totalIva).toFixed(2);
                    this.totalAjuste=this.totalVenta-(this.totalNeto+this.totalIva);
                    this.totalAjuste=0;
                    // this.totalNeto=parseInt(this.totalNeto)-parseInt(resta);
                    // const suma=(parseInt(newPrecio)*parseInt(fila.cantidad));
                    // this.totalNeto=parseInt(this.totalNeto)+parseInt(suma);
                    // fila.valor=newPrecio;
                    // fila.precio=new Intl.NumberFormat("de-DE").format(parseFloat(newPrecio));
                    // this.totalIva=Math.round(((parseInt(this.totalNeto)*19)/100),0);
                    // this.totalVenta=parseInt(this.totalNeto)+parseInt(this.totalIva);
                    this.editPrecio='';
                }
            };
            this.focusBarra();
        },
        save(fila,newCantidad) {
            console.log('save');
            if(newCantidad!=''){
                if(parseInt(newCantidad)!=0){
                    //verificamos el stock
                    const resultado = this.inventalista.find( datos => datos.sku === fila.sku );
                    if (resultado){
                        if(parseInt(resultado.stockSku)>=parseInt(newCantidad)){
                            let imp=1+(this.impuesto/100);
                            const resta=(parseInt(fila.valor)*parseInt(fila.cantidad));
                            this.totalVenta=parseInt(this.totalVenta)-parseInt(resta);
                            const suma=(parseInt(fila.valor)*parseInt(newCantidad));
                            this.totalVenta=parseInt(this.totalVenta)+parseInt(suma);
                            fila.cantidad=newCantidad;
                            this.totalNeto=this.totalVenta/imp;
                            this.totalNeto=parseFloat(this.totalNeto).toFixed(2);
                            this.totalIva=(this.totalNeto*this.impuesto)/100;
                            this.totalIva=parseFloat(this.totalIva).toFixed(2);
                            this.editCantidad='';
                        } else {
                            this.Msg='Cantidad Supera Stock';
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    }
                }
            };
            this.focusBarra();
        },
        cancel(fila) {
            console.log('cancel');
            this.editCantidad='';
            this.editPrecio='';
            this.focusBarra();
        },
        open(fila) {
            console.log('open');
            this.editCantidad='';
            this.editPrecio='';
            this.focusBarra();
        },
        close(fila) {
            console.log('close');
            this.editCantidad='';
            this.editPrecio='';
            this.focusBarra();
        },
    }
}
</script>