<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">DashBoard</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">DashBoard</h3> <!-- Mobile Screen -->
    <div v-if="vista===true" class="mt-5">
    <v-card>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="12" md="6">
            <DashProductos :usu="usu" :contrato="contrato"/>
          </v-col>
          <v-col cols="12" md="6">
            <DashCompraVenta :usu="usu" :contrato="contrato"/>
          </v-col>
          <v-col cols="12" md="6">
            <DashCalidad :usu="usu" :contrato="contrato"/>
          </v-col>
          <v-col cols="12" md="6">
            <DashSetup :usu="usu" :contrato="contrato"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    </div>
    <div v-else-if="vista===false" class="mt-5">
      <v-card>
        <v-card-subtitle><v-icon class="red--text">cancel</v-icon><span class="ml-2">Solicitud Denegada</span></v-card-subtitle>
      </v-card>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>
<script>
import axios from 'axios';
import DashProductos from '@/components/DashProductos.vue';
import DashSetup from '@/components/DashSetup.vue';
import DashCompraVenta from '@/components/DashCompraVenta.vue';
import DashCalidad from '@/components/DashCalidad.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'DashBoard',
  components: { DashProductos, DashSetup,DashCompraVenta,DashCalidad,dialogo },
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      vista:null
    }
  },
  async mounted(){
    await this.entrada();
  },
  methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      //console.log(datos);
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_1_1!=0) {
          this.vista=true;
        } else { this.vista=false; }
      } else { this.vista=false; }
      this.loading=false;
    }
  }
}
</script>